import React, { useEffect, useState } from "react";
import "./assets/css/ManualUpsale.css";
import { Space, Table, Tag, Tooltip } from 'antd';
import { Popconfirm } from 'antd';
import { MdDeleteOutline } from 'react-icons/md';
import { PrimaryButton, SimpleButton } from "../../pages/component/Button";
import { CancelButton } from '../../pages/component/Button'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput, FormSelect } from '../../pages/component/formInput';
import * as ManualAction from "../../store/actions/ManualUpsale/index";
import { connect } from "react-redux";
// import { FaEdit } from 'react-icons/fa';
import * as yup from "yup";
import { message ,Select} from 'antd';
import { Link } from "react-router-dom";
// import baseUrl from '../config.json'




function ManualUpsale({
    cancel,
    isCode,
    Red_ManualUpsale,
    modal,
    get_AllPackages,
    get_AllLeadsss,
    SaveManualUpSale,
    isEmail,
    isName
}) {

    var get_access_token = localStorage.getItem("access_token");
    var get_company_code = localStorage.getItem("company_code");
    const [messageApi, contextHolder] = message.useMessage();
    const [isUpdateBtn, setUpdatebtn] = useState(false)
    // const [pageSize, setPageSize] = useState(10);
    const [isLoading, setLoading] = useState(false)
    const [isSavedEdu, setSavedEdu] = useState(false)
    const packeges = Red_ManualUpsale?.getPackage?.[0]?.res?.data
    const Clients = Red_ManualUpsale?.getLeads?.[0]?.res?.data


    const EditBack = () => {
        cancel('read')
    }

    // console.log(isCode, 'isCode')

    const ManualUpsaleSchema = yup.object().shape({
        name: yup.string().required("name is required"),
        package_id: yup.string().required("package is required"),
        amount: yup.string().required("amount is required"),
        is_payment_recived: yup.string().required("payment recived is required"),
        email: yup.string().required("email is required"),
    });

    useEffect(() => {
        get_AllPackages()
        get_AllLeadsss()
    }, [])


    // useEffect(() => {
    //     if (mode2 == "create") {
    //         reset({
    //             Emp_name: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Emp_name,
    //             Desig_name: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Desig_name,
    //             Dept_name: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Dept_name,
    //         });
    //     } else {
    //         reset(
    //             {
    //                 Emp_name: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Emp_name,
    //                 Desig_name: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Desig_name,
    //                 Dept_name: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Dept_name,
    //             },
    //         )
    //     }

    // }, [Red_AppointEducation?.data?.[0]?.res?.data?.[0]])




    // useEffect(() => {
    //     if (isUpdate) {
    //         reset({
    //             EduCode: Red_AppointEducation?.data?.[0]?.res?.data?.Edu_Code,
    //             EduYear: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Edu_Year,
    //             EduGrade: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Edu_Grade,
    //             Topflag: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.Top_flag,
    //             institutecode: Red_AppointEducation?.data?.[0]?.res?.data?.[0]?.institute_code,
    //         });
    //     }


    // }, [Red_AppointEducation?.getSavedData?.[0]?.res?.data?.[0]])
    // console.log(Red_AppointEducation, 'R')

    // const EduData = Red_AppointEducation?.getEdu?.[0]?.res?.data
    // const InsData = Red_AppointEducation?.getInsti?.[0]?.res?.data
    // const GradeData = Red_AppointEducation?.getGrade?.[0]?.res?.data


    // ==================================================
    const submitForm = async (data) => {
        try {
            const isValid = await ManualUpsaleSchema.validate(data);
            if (isValid) {
                SaveForm(data)
            }
        } catch (error) {
            console.error(error);
        }
    };


    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: {
            name: isName,
            package_id:"",
            amount:"",
            is_payment_recived: "",
            email: isEmail,   
        },
        mode: "onChange",
        resolver: yupResolver(ManualUpsaleSchema),
    });


    const SaveForm = async (data) => {
        setLoading(true)
        try {
            const response = await SaveManualUpSale({
                name: data?.name,
                package_id: data?.package_id,
                amount: data?.amount,
                is_payment_recived: data?.is_payment_recived,
                email: data?.email,
            });

            if (response && response.success) {
                messageApi.success("Save Upsale");
                setTimeout(() => {
                    cancel('read')
                    setSavedEdu(true)
                }, 3000);
                setLoading(false)
            } else {
                const errorMessage = response?.message || 'Failed to Save Upsale';
                messageApi.error(errorMessage);
                setLoading(false)
            }
        } catch (error) {
            console.error("Error occurred while Save Upsale:", error);
            messageApi.error("An error occurred while Save Upsale");
            setLoading(false)
        }
    };






    // const UdpateForm = async (data) => {
    //     setLoading(true)
    //     try {
    //         const response = await UpdateEducation({
    //             srNo: isUpdate,
    //             EduCode: data?.EduCode,
    //             EduYear: data?.EduYear,
    //             EduGrade: data?.EduGrade,
    //             Topflag: data?.Topflag,
    //             institutecode: data?.institutecode,
    //         });

    //         if (response && response.success) {
    //             messageApi.success("Save Upsale");
    //         } else {
    //             const errorMessage = response?.message || 'Failed Save Upsale ';
    //             messageApi.error(errorMessage);
    //         }
    //     } catch (error) {
    //         console.error("Error occurred while Save Upsale:", error);
    //         messageApi.error("An error occurred while Save Upsale");
    //     }
    // };





    return (
        <>
            {contextHolder}
            <div className="backGrounD_container">
                <div className="ModalUpsale">
                    <div className="row">
                        <div className="col-12">
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="form-group formBoxCountry">
                                    <FormInput
                                        label={'name'}
                                        placeholder='name'
                                        id="name"
                                        name="name"
                                        type='text'
                                        showLabel={true}
                                        errors={errors}
                                        control={control}
                                        readOnly={true}
                                    />
                                    <FormInput
                                        label={'Email'}
                                        placeholder='Email'
                                        id="email"
                                        name="email"
                                        type='text'
                                        showLabel={true}
                                        errors={errors}
                                        control={control}
                                        readOnly={true}
                                    />
                                    <FormSelect
                                        label={'packages'}
                                        placeholder='packages'
                                        id="package_id"
                                        name="package_id"
                                        options={packeges?.map(item => ({
                                            id: item.package_id,
                                            name: item.name
                                        }))
                                        }
                                        showLabel={true}
                                        errors={errors}
                                        control={control}
                                    />
                                    <FormInput
                                        label={'amount'}
                                        placeholder='amount'
                                        id="amount"
                                        name="amount"
                                        type='number'
                                        showLabel={true}
                                        errors={errors}
                                        control={control}
                                    />
                                    <FormSelect
                                        label={'Payment recived'}
                                        placeholder={'Payment recived'}
                                        id="is_payment_recived"
                                        name="is_payment_recived"
                                        options={[
                                            {
                                                id: 1,
                                                name: 'Yes',
                                            },
                                            {
                                                id: 0,
                                                name: 'No',
                                            },
                                        ]}
                                        showLabel={true}
                                        errors={errors}
                                        control={control}
                                    />
                                </div>
                                <div className='ManualUpSaleBtnBox'>
                                    <CancelButton onClick={EditBack} title={'Cancel'} />
                                    <SimpleButton type={'submit'} loading={isLoading} title="Save" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
function mapStateToProps({ Red_ManualUpsale }) {
    return { Red_ManualUpsale };
}
export default connect(mapStateToProps, ManualAction)(ManualUpsale);