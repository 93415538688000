import React, { useState, useEffect, useRef } from 'react'
import OrderDataTable from 'react-data-table-component'
import '../components/assets/css/ordersTable.css'
import SearchBar from './SearchBar';
import useClipboard from "react-use-clipboard";
import stripe_logo from '../assets/images/stripelogo.webp'
import paypal_logo from '../assets/images/paypal.webp'
import upwork_logo from '../assets/images/upworklogo.webp'
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import { RxCrossCircled as Close_ico } from "react-icons/rx";
import { AiOutlineDownload as Download_ico } from "react-icons/ai";
import { AiOutlineFileText as File_ico } from "react-icons/ai";
import { FiCopy as Copy_ico } from "react-icons/fi";
import word_ico from '../assets/images/icons/wordIco.webp'
import excel_ico from '../assets/images/icons/excel_ico.webp'
import { FcImageFile as Picture_ico } from "react-icons/fc";
import userImg from '../assets/images/icons/userIco.png'
import { GrFormView as View_ico } from "react-icons/gr";
import { Tooltip } from "react-tooltip";
import { useReactToPrint } from 'react-to-print';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { Skeleton, message, Popconfirm, Space, Table, Tag } from 'antd';
import * as Allorder_Action from "../store/actions/allOrder/index";
import { connect } from "react-redux";
import { } from 'antd';


const config = require('../components/config.json')

function AllOrdersCom({ getOrderList, changeJobStatus, getOrderFiles }) {
    const [isOrdersData, setOrdersData] = useState([]);
    const [Isrows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(null);

    const getPrimaryData = async () => {
        setLoading(true)
        const response = await getOrderList({ page: 0, search: search == "" ? null : search })
        if (response?.success == "success") {
            setOrdersData(response.data)
            setRows(response.totalRows)
            setLoading(false)
        }
        else {
            message.error('Something went wrong')
            setLoading(false)
        }
    }

    const ChangeStatus = async (id, complete) => {
        const response = await changeJobStatus({ id: id, complete: complete })
        if (response?.success == "success") {
            message.success('Successfully updated')
            getPrimaryData()
        }
        else {
            console.log(response)
            message.error('Something went wrong')
        }
    }


    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(isOrdersData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "data" + fileExtension);
    }

    const OrderSearchFilter = (e) => {
        setSearch(e?.target?.value)
    }

    const getOrderFilesFunction = async (id) => {
        const response = await getOrderFiles({ id: id, })
        if (response?.success == "success") {
            console.log(response)
        }
        else {
            message.error('Something went wrong')
        }
    }

    useEffect(() => {
        getPrimaryData()
    }, [search])
























    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "All Orders",
        pageStyle: "print",
    });

    const handlehideShow = () => {
        document.getElementById('hidediv').style.display = 'block'
        setTimeout(() => {
            document.getElementById('hidediv').style.display = 'none'
        }, 1);
    }
    const [isCopied, setCopied] = useClipboard(JSON.stringify(isOrdersData));



    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (row) => (
                <>
                    <div className='dataTableBox'>
                        <div className='dataTableFlexBox'>
                            <h5>
                                <img src={userImg} />
                            </h5>
                            <h6>
                                <span data-tooltip-id='row-name' data-tooltip-content={`Name :` + " " + row.name} className='tableName'>
                                    {row.name}
                                </span>
                                <span data-tooltip-id='row-email' data-tooltip-content={`Email :` + " " + row.email} className='tableEmail'>
                                    {row.email}
                                </span>
                            </h6>
                        </div>
                        <Tooltip
                            id="row-name"
                            place="bottom"
                        />
                        <Tooltip
                            id="row-email"
                            place="bottom"
                        />
                        <Tooltip
                            id="row-number"
                            place="bottom"
                        />
                    </div>
                </>
            ),
        },
        {
            title: 'Number',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Package Detail',
            // dataIndex: 'address',
            key: 'address',
            render: (row) => (
                <div className='pacDetailsBox'>
                    <>
                        {
                            row?.package_name ?
                                <span className="mt-2 package_name" data-tooltip-id='package_name' data-tooltip-content={`Package name :` + " " + row?.package_name}>
                                    {row?.package_name}
                                </span> :
                                <span className="notFoundMsg">Not Found</span>
                        }
                        {
                            row?.amount ?
                                <span data-tooltip-id='amount' className='mb-2 mt-2 package_name' data-tooltip-content={`Amount:` + " " + `$${row?.amount}`}>
                                    {`$${row?.amount}`}</span> :
                                <span className="notFoundMsg">Not Found</span>
                        }
                    </>
                    <Tooltip
                        id="package_name"
                        place="bottom"
                    />
                    <Tooltip
                        id="amount"
                        place="bottom"
                    />
                </div>
            ),
        },
        {
            title: 'Payment',
            key: 'Payment',
            render: (row) =>
                <div className='dataTableBox'>
                    {row.payment_method == "stripe" ?
                        <img src={stripe_logo} alt="" />
                        : row.payment_method == "paypal" ?
                            <img src={paypal_logo} alt="" />
                            : row.payment_method == "upwork" ?
                                <img src={upwork_logo} alt="" />
                                : row.payment_method == "Stripe" ?
                                    <img src={stripe_logo} alt="" />
                                    : row.payment_method == "Paypal" ?
                                        <img src={paypal_logo} alt="" />
                                        : row.payment_method == "Upwork" ?
                                            <img src={upwork_logo} alt="" />
                                            : <span className="notFoundMsg">Not Found</span>
                    }
                </div>,
        },
        {
            title: 'Invoice',
            key: 'Invoice',
            render: (row) =>
                <div className='dataTableIconBox'>
                    {row.invoice ?
                        <a href={`http://192.168.88.38:8000${row.invoice !== null && row.invoice !== undefined && row.invoice !== "" ? row.invoice.split('/uploads')[1] : ""}`} target='_blank'>
                            <View_ico />
                        </a>
                        :
                        <span className="notFoundMsg">Not Found</span>}
                    <Tooltip
                        anchorSelect=".row-invoice"
                        place="bottom"
                        content="View Invoice"
                    />
                </div>,
        },
        {
            title: 'Files',
            key: 'Files',
            render: (row) =>
                <div className='dataTableIconBox'>
                    <button className='viewFileBox' onClick={() => getOrderFilesFunction(row.id)}><File_ico className='fileViewIcon' /></button>
                    <Tooltip
                        anchorSelect=".viewFileBox"
                        place="bottom"
                        content="View file Box"
                    />
                </div>
        },
        {
            title: 'Links',
            key: 'Links',
            render: (row) =>
                <div className='dataTableIconBox'>
                    <button className='viewLinkBox'><File_ico className='fileViewIcon' /></button>
                    <Tooltip
                        anchorSelect=".viewLinkBox"
                        place="bottom"
                        content="View link Box"
                    />
                </div>
        },
        {
            title: 'Order Status',
            key: 'Order_Status',
            render: (row) =>
                <div className='dataTableBox'>
                    <Popconfirm title="Are you sure you want to change the status?" onConfirm={() => {
                        ChangeStatus(row.id, row.complete == "false" ? "true" : "false")
                    }}>
                        <button className='commonOrderbtn'>{row.complete == "false" ? "Incomplete Order" : "Complete Order"}</button>
                    </Popconfirm>
                </div>
        },
        {
            title: 'Created at',
            key: 'Created_at',
            render: (row) =>
                <div className='dataTableBox'>
                    <span style={{ fontSize: "11px" }}>
                        {row.created_at.slice(0, 10)}
                    </span>
                </div>
        },
    ];

    return (
        <>
            <div className="orderBox">
                <h4 className='orderBoxHeadOne'>Orders Details</h4>
                <div className="innerOrderBox">
                    <div className="btnBox d-flex justify-content-between w-100" >
                        <h4 className='orderBoxHeadTwo'>Orders Details</h4>

                        <div className='d-flex'>
                            <button onClick={setCopied} className='copyBelow'><Copy_ico /></button>
                            <button className='makeExcelFile' onClick={(e) => { exportToExcel() }}>Export Excel</button>
                            <button className='PrintFile' onClick={() => {
                                handlehideShow()
                                handlePrint()
                            }}>Print</button>


                            <Tooltip
                                anchorSelect=".copyBelow"
                                place="bottom"
                                content="Copy Below Data"
                                style={{ zIndex: "9999" }}
                            />
                            <Tooltip
                                anchorSelect=".makeExcelFile"
                                place="bottom"
                                content="Make Excel File of below Data"
                                style={{ zIndex: "9999" }}
                            />
                            <Tooltip
                                anchorSelect=".makePDFFile"
                                place="bottom"
                                content="Make PDF File of below Data"
                                style={{ zIndex: "9999" }}
                            />
                            <Tooltip
                                anchorSelect=".PrintFile"
                                place="bottom"
                                content="Print out of below data"
                                style={{ zIndex: "9999" }}
                            />  
                            <SearchBar
                                {...{ OrderSearchFilter, search }}
                            />
                        </div>

                    </div>

                </div>


                {loading ?
                    <div className='p-4' style={{ backgroundColor: 'white' }}>
                        <Skeleton active />
                        <div className='pt-4'>
                            <Skeleton active />
                        </div>
                    </div>
                    :
                    <>
                        <Table columns={columns} dataSource={isOrdersData} />
                    </>
                }

                <div ref={componentRef} id='hidediv' style={{ display: "none" }}>
                    <table style={{ width: "100%", padding: "50px" }}>
                        <thead className='printTableHead'>
                            <tr>
                                <th>Customer Details</th>
                                <th>Phone</th>
                                <th>Pack Details</th>
                                <th>Payment</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody className='printTableBody'>
                            {isOrdersData?.map((items) => {
                                return (
                                    <tr key={items?.id}>
                                        <td style={{ display: "flex", flexDirection: "column" }}>
                                            <span> {items?.name ? items?.name : "Not Found Name"}</span>
                                            <span>{items?.email ? items?.email : "Not Found Email"}</span>
                                        </td>
                                        <td>
                                            <span>{items?.number}</span>
                                        </td>
                                        <td style={{ display: "flex", flexDirection: "column" }}>
                                            <span> {items?.package_name ? items?.package_name : "Not Found package name"}</span>
                                            <span>{items?.amount ? `${"$" + items?.amount}` : ""}</span>
                                        </td>
                                        <td>
                                            <span>{items?.payment_method ? items?.payment_method : "Not Found Payment Method"}</span>
                                        </td>
                                        <td>
                                            <span> {items?.created_at ? items?.created_at.slice(0, 10) : "Not Found"}</span>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

function mapStateToProps({ allOrders }) {
    return { allOrders };
}
export default connect(mapStateToProps, Allorder_Action)(AllOrdersCom);