import React, { useEffect, useState } from 'react';
import styled, { flex } from '@xstyled/styled-components';
import { borderRadius, grid } from './constants';
import Styled from './TaskCard.module.css'
import ReadMoreReact from 'read-more-react';
import { DateTime } from "luxon";
import { Modal, message, Skeleton, Spin, Empty } from 'antd';
import * as AllTask_Action from "../../../../store/actions/Task/index";
import { connect } from "react-redux";
import { Tabs, Upload, Button, Popconfirm } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ReactTimeAgo from 'react-time-ago'
import { FormInput, TextArea, FormSelect } from '../../../component/formInput';
import { createTaskSchema } from '../../schema';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const imageSize = 40;
const CloneBadge = styled.div`
  background: #79f2c0;
  bottom: ${grid / 2}px;
  border: 2px solid #57d9a3;
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 10px;
  position: absolute;
  right: -${imageSize / 3}px;
  top: -${imageSize / 3}px;
  transform: rotate(40deg);
  height: ${imageSize}px;
  width: ${imageSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.a`
  border-radius: ${borderRadius}px;
  box-shadow: ${({ isDragging }) => (isDragging ? `2px 2px 1px #A5ADBA` : 'none')};
  box-sizing: border-box;
  padding: 12px;
  min-height: ${imageSize}px;
  margin-bottom: ${grid}px;
  user-select: none;

  /* anchor overrides */
  color: #091e42;

  &:hover,
  &:active {
    color: #091e42;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;

const Avatar = styled.img`
  width: ${imageSize}px;
  height: ${imageSize}px;
  border-radius: 50%;
  margin-right: ${grid}px;
  flex-shrink: 0;
  flex-grow: 0;
`;

const Content = styled.div`
  /* flex child */
  flex-grow: 1;
  /*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flex-basis: 100%;
  /* flex parent */
  display: flex;
  flex-direction: column;
`;

const BlockQuote = styled.div`
  &::before {
    content: open-quote;
  }
  &::after {
    content: close-quote;
  }
`;

const Footer = styled.div`
  display: flex;
  margin-top: ${grid}px;
  align-items: center;
`;

const Author = styled.small`
  flex-grow: 0;
  margin: 0;
  background-color: ${(props) => props.colors.soft};
  border-radius: ${borderRadius}px;
  font-weight: normal;
  padding: ${grid / 2}px;
`;

const QuoteId = styled.small`
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  font-weight: normal;
  text-overflow: ellipsis;
  text-align: right;
`;

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}


function QuoteItem(props) {
  const [taskDetailLoading, setTaskDetailLoading] = useState(false)
  const [taskId, setTaskId] = useState()
  const [taskUserId, setTaskUserId] = useState()
  const [taskFile, setTaskFile] = useState()
  const [taskDetail, setTaskDetail] = useState([])
  const [attachmentValidation, setAttachmentValidation] = useState(false)

  const { quote, GetAllProjects, GetAllUsers, GetAllPriorties, isDragging, isGroupedOver, provided, style, isClone, index, UploadTask, GetTaskDetail, CreateCommentE, GetAllComments, CreateLikes, CreateUnLikes, SaveUpdateComment, DeleteComment, LoadComments, ReplyComment, GetAllattachment, DeleteAttachment, DeleteTask, GetAllTasks, UpdateTask, GetTimeByID, GetUserById } = props;
  // modals=====================================================
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = async (R, A) => {
    setTaskUserId(A)
    setTaskId(R)
    setTaskDetailLoading(true)
    setIsModalOpen(true);
    const loadData = await GetTaskDetail(R)
    if (loadData?.success == 'success') {
      setTaskDetailLoading(false)
      setTaskDetail(loadData?.data)
    }
    else {
      message.error('something went wrong')
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [isAttachment, setIsAttachment] = useState(false);
  const isAttachmentModal = (R) => {
    setTaskId(R)
    setIsAttachment(true);
    setTaskFile("")
    setAttachmentValidation(false)
  };
  const isAttachmentModalCancel = () => {
    setIsAttachment(false);
  };
  // modals=====================================================
  const [formData, setFormData] = useState(new FormData());

  const propsDR = {
    onChange(info) {
      setAttachmentValidation(false)
      setTaskFile(info?.file?.originFileObj)
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const [attachmentLoader, setAttachmentLoader] = useState(false)
  const SaveFile = async () => {
    setAttachmentLoader(true)
    if (taskFile !== "") {
      formData.append('task_file', taskFile)
      formData.append('taskId', taskId)
      const Fileholder = await UploadTask(formData)
      if (Fileholder?.success == 'success') {
        message.success('successFully Uploaded')
        isAttachmentModalCancel()
        GetAllTasks()
        formData.delete("taskId");
        setAttachmentLoader(false)
        formData.delete("task_file");
      }
      else if (Fileholder?.message == 'File already exists') {
        message.error('File already exists')
        isAttachmentModalCancel()
        formData.delete("taskId");
        formData.delete("task_file");
        GetAllTasks()
        setAttachmentLoader(false)
      }
      setAttachmentLoader(false)
    }
    else {
      setAttachmentValidation(true)
      setAttachmentLoader(false)
    }
  }
  const userId = localStorage.getItem('user_id')

  const Comment = () => {
    const [commentCache, setCommentCache] = useState()
    const [allComments, setAllComments] = useState([])
    const [btnLoading, setBtnLoading] = useState(false)
    const [commentLoading, setCommentLoading] = useState(false)
    const [Validation, setValidation] = useState(false)
    const [updateCommentLet, setUpdateCommentLet] = useState()
    const [commentReply, setCommentReply] = useState([])
    const [settingCurrentComment, setSettingCurrentComment] = useState()


    const [replyStore, setReplyStore] = useState()
    const [replyValidation, setReplyValidation] = useState(false)


    useEffect(() => {
      allCommentfunction()
    }, [])

    const allCommentfunction = async () => {
      setCommentLoading(true)
      const fetchComment = await GetAllComments(taskId)
      if (fetchComment?.success == 'success') {
        setAllComments(fetchComment?.data)
        setCommentLoading(false)
      }
      setCommentLoading(false)
    }


    const CreateComment = async () => {
      if (commentCache !== "" && commentCache !== undefined) {
        setBtnLoading(true)
        const CommetnResponse = await CreateCommentE({ lead_id: taskId, comments: commentCache })
        if (CommetnResponse?.success == 'success') {
          message.success('Comment Added')
          setCommentCache("")
          setBtnLoading(false)
          allCommentfunction()
        }
        setBtnLoading(false)
      }
      else {
        setValidation(true)
      }
    }

    const CreateLike = async (Id) => {
      const Likes = await CreateLikes(Id)
      if (Likes?.success == "success") {
        setAllComments((prevComments) =>
          prevComments?.map((comment) =>
            comment.comment_id === Id ? { ...comment, self_liked: 'true', like_counts: comment?.like_counts + 1 } : comment
          )
        );
        setCommentReply((prevComments) =>
          prevComments?.map((comment) =>
            comment.comment_id === Id ? { ...comment, self_liked: 'true', like_counts: comment?.like_counts + 1 } : comment
          )
        );

      }
    }

    const CreateUnLike = async (Id) => {
      const Likes = await CreateUnLikes(Id)
      if (Likes?.success == "success") {
        setAllComments((prevComments) =>
          prevComments?.map((comment) =>
            comment.comment_id === Id ? { ...comment, self_liked: 'false', like_counts: comment?.like_counts - 1 } : comment
          )
        );
        setCommentReply((prevComments) =>
          prevComments?.map((comment) =>
            comment.comment_id === Id ? { ...comment, self_liked: 'false', like_counts: comment?.like_counts - 1 } : comment
          )
        );

      }
    }

    const DeleteCurrnetComment = async (Id) => {
      const Likes = await DeleteComment(Id)
      if (Likes?.success == "success") {
        allCommentfunction()
        LoadMoreComments(Id)

      }
    }
    const [visibleBox, setVisibleBox] = useState()

    const userId = localStorage.getItem('user_id')
    const UpdateSingleComment = (Id) => {
      setVisibleBox(Id)
    }

    const [updateCommenyLoader, SetUpdateCommenyLoader] = useState(false)
    const SaveUpdateCommentFuc = async (Id, comment) => {
      SetUpdateCommenyLoader(true)
      const Likes = await SaveUpdateComment({ comment_id: Id, comments: updateCommentLet || comment })
      if (Likes?.success == "success") {
        setVisibleBox(null)
        allCommentfunction()
        LoadMoreComments(Id)
        SetUpdateCommenyLoader(false)
      }
      SetUpdateCommenyLoader(false)
    }



    const [isReplyBox, setIsReplyBox] = useState(false)
    const [isSeeComment, setIsSeeComment] = useState(false)
    const [moreCommentLoader, setMoreCommentLoader] = useState(false)

    const LoadMoreComments = async (Id) => {
      setMoreCommentLoader(true)
      setIsSeeComment(true)
      setIsReplyBox(false)
      if (settingCurrentComment == Id) {
        setSettingCurrentComment(null)
        setMoreCommentLoader(false)
      }
      else {
        setSettingCurrentComment(Id)
        setCommentReply([])
        const Likes = await LoadComments(Id)
        if (Likes?.success == "success") {
          setCommentReply(Likes?.data)
          setMoreCommentLoader(false)
        }
        setMoreCommentLoader(false)
      }
    }

    const OpenReplyBox = async (Id) => {
      setIsReplyBox(true)
      setIsSeeComment(false)
      if (settingCurrentComment == Id) {
        setSettingCurrentComment(null)
      }
      else {
        setSettingCurrentComment(Id)
        setCommentReply([])
        const Likes = await LoadComments(Id)
        if (Likes?.success == "success") {
          setCommentReply(Likes?.data)
        }
      }
    }


    const AddReplyComment = async (Id) => {
      const Likes = await ReplyComment({ parent_comment_id: Id, comments: replyStore, lead_id: taskId })
      if (Likes?.success == "success") {
        allCommentfunction()
        setIsReplyBox(false)
      }
    }
    return (
      <>
        <div className={Styled.CommentInput}>
          <input style={{ border: Validation && '1px solid red' }} value={commentCache} onChange={(e) => {
            setValidation(false)
            setCommentCache(e.target.value)
          }} placeholder='Add a comment' /><button onClick={() => CreateComment()}>{btnLoading ? <Spin /> : "Submit"}</button>
        </div>
        <div className={Styled.CommentSection}>
          {commentLoading ? <Skeleton /> :
            <>
              {allComments !== undefined && allComments !== null && allComments !== '' && allComments.length > 0 ? allComments?.map(dataComment => {
                const timestamp = new Date().getTime();
                return (
                  <>
                    <div className={Styled.CommentBox}>
                      <div className={Styled.CommentHeading}>
                        <div className={Styled.AvatarComment}>
                          {dataComment?.name?.charAt(0)}
                        </div>
                        <div className='d-flex align-items-center'>
                          <h6>{dataComment?.name}</h6><i class="fa fa-circle" aria-hidden="true"></i>
                          <p>
                            {DateTime.fromSQL(dataComment?.created_at).toFormat("ff")}
                          </p>

                        </div>
                      </div>
                      {visibleBox == dataComment.comment_id ?
                        <div>
                          <textarea onChange={(e) => { setUpdateCommentLet(e.target.value) }} defaultValue={dataComment?.comments} placeholder='Update comment' className={Styled.UpdateCommentBox}></textarea>
                          <div className='d-flex align-items-center justify-content-end'>
                            <Button disabled={updateCommenyLoader} onClick={() => SaveUpdateCommentFuc(dataComment.comment_id, dataComment?.comments)} className={Styled.UpadateCommentBtn}>{updateCommenyLoader ? <Spin /> : "Save"}</Button>
                          </div>
                        </div>
                        :
                        <p>{dataComment?.comments}</p>
                      }
                      <div className={Styled.CommentAction}>
                        <button onClick={() => {
                          dataComment?.self_liked !== 'true' ?
                            CreateLike(dataComment?.comment_id)
                            :
                            CreateUnLike(dataComment?.comment_id)
                        }}><i style={{ color: dataComment?.self_liked == 'true' ? 'blue' : null }} class="fa fa-thumbs-o-up pr-1" aria-hidden="true"></i> {dataComment?.like_counts}</button>


                        <div onClick={() => OpenReplyBox(dataComment?.comment_id)} className={Styled.RepluButton}>Reply</div>
                        {userId == dataComment?.user_id ?
                          <>
                            <Popconfirm
                              title="Delete the comment"
                              description="Are you sure to delete this comment?"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => DeleteCurrnetComment(dataComment?.comment_id)}
                            >
                              <div className={Styled.RepluButtonDelete}><i class="fa fa-trash-o" aria-hidden="true"></i></div>
                            </Popconfirm>
                            <div onClick={() => UpdateSingleComment(dataComment?.comment_id)} className={Styled.RepluButtonUpdate}><i class="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                          </>
                          : null}
                        <div className={Styled.MoreReplyBtn} onClick={() => LoadMoreComments(dataComment?.comment_id)}><p>{moreCommentLoader ? 'Loading...' : `${dataComment?.reply_count} ${dataComment?.reply_count == 0 ? '' : 'more'} replies`}</p></div>
                      </div>

                      {isSeeComment == true ?
                        <>
                          {dataComment?.comment_id == settingCurrentComment ?
                            <>
                              {commentReply?.map(dataReply => {
                                return (
                                  <>
                                    <div className={Styled.AllReplies}>
                                      <div className={`${Styled.CommentHeading}`}>
                                        <div className={Styled.AvatarComment}>
                                          {dataReply?.name?.charAt(0)}
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          <h6>{dataReply?.name}</h6><i class="fa fa-circle" aria-hidden="true"></i>
                                          <p><ReactTimeAgo date={timestamp} locale="en-US" /></p>
                                        </div>
                                      </div>



                                      {visibleBox == dataReply.comment_id ?
                                        <div>
                                          <textarea onChange={(e) => { setUpdateCommentLet(e.target.value) }} defaultValue={dataReply?.comments} placeholder='Update comment' className={Styled.UpdateCommentBox}></textarea>
                                          <div className='d-flex align-items-center justify-content-end'>
                                            <Button onClick={() => SaveUpdateCommentFuc(dataReply.comment_id, dataReply?.comments)} className={Styled.UpadateCommentBtn}>Save</Button>
                                          </div>
                                        </div>
                                        :
                                        <p>{dataReply?.comments}</p>
                                      }
                                      <div className={Styled.CommentAction}>
                                        <button onClick={() => {
                                          dataReply?.self_liked !== 'true' ?
                                            CreateLike(dataReply?.comment_id)
                                            :
                                            CreateUnLike(dataReply?.comment_id)
                                        }}><i style={{ color: dataReply?.self_liked == 'true' ? 'blue' : null }} class="fa fa-thumbs-o-up pr-1" aria-hidden="true"></i> {dataReply?.like_counts}</button>


                                        {userId == dataReply?.user_id ?
                                          <>
                                            <Popconfirm
                                              title="Delete the comment"
                                              description="Are you sure to delete this comment?"
                                              okText="Yes"
                                              cancelText="No"
                                              onConfirm={() => DeleteCurrnetComment(dataReply?.comment_id)}
                                            >
                                              <div className={Styled.RepluButtonDelete}><i class="fa fa-trash-o" aria-hidden="true"></i></div>
                                            </Popconfirm>
                                            <div onClick={() => UpdateSingleComment(dataReply?.comment_id)} className={Styled.RepluButtonUpdate}><i class="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                                          </>
                                          : null}
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                              )}
                            </> : null}
                        </> : null}

                      {isReplyBox == true ?
                        <>

                          {dataComment?.comment_id == settingCurrentComment ?
                            <div className={Styled.AllReplies}>
                              <input placeholder='Add reply' onChange={(e) => setReplyStore(e.target.value)} /> <Button onClick={() => AddReplyComment(dataComment.comment_id)}>Save</Button>
                            </div> : null}
                        </> : null
                      }

                    </div>
                    <hr className='mt-0'></hr>

                  </>
                )
              }
              ) : <Empty description={
                <span>
                  No Comments
                </span>
              } />}
            </>
          }
        </div>
      </>
    )
  }

  const Description = () => {
    return (
      <>
        {taskDetail?.map(data => (
          <p>{data?.summary}</p>
        ))}
      </>
    )
  }

  const Attachments = () => {
    const [allAttachment, setAttacment] = useState()

    const LoadAttachment = async () => {
      const Attachment = await GetAllattachment(taskId)
      if (Attachment?.success == 'success') {
        setAttacment(Attachment?.data)
      }
    }
    useEffect(() => {
      LoadAttachment()
    }, [])

    const downloadFile = async (Prop, Name) => {
      try {
        const response = await fetch(Prop);
        if (response.ok) {
          const blob = await response.blob();
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = Name || Name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(blobUrl);
        } else {
          console.error('Failed to fetch the file:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching the file:', error.message);
      }
    };


    const Delete = async (taskId) => {
      const Attachment = await DeleteAttachment(taskId)
      if (Attachment?.success == 'success') {
        LoadAttachment()
      }
    }
    return (
      <>
        <div className={Styled.FileList}>
          {allAttachment !== undefined && allAttachment !== null && allAttachment !== '' && allAttachment.length > 0 ? allAttachment?.map(data =>
            <div className={Styled.FileFormat}>
              <div>
                <a download={true} target='Blank' href={`https://portal-backend.eliteghostwriter.us/${data?.file_path}`}>{data?.file_name}</a>
              </div>
              <div>
                <button onClick={() => downloadFile(`https://portal-backend.eliteghostwriter.us/${data?.file_path}`, data?.file_name)} className={Styled.FetchDownload}><i class="fa fa-floppy-o" aria-hidden="true"></i></button>
                {userId == taskUserId ?
                  <Popconfirm
                    title="Delete the Attachment"
                    description="Are you sure to delete this Attachment?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => Delete(data?.attachment_id)}
                  >
                    <button className={Styled.DeleteFile}><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                  </Popconfirm>
                  : null}
              </div>
            </div>
          ) : <Empty description={
            <span>
              No Attachments
            </span>
          } />}
        </div>
      </>
    )
  }

  const UserAction = () => {
    const [allCompany, setAllCompany] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [allPriorties, setAllPriorties] = useState([])
    const [updateLoading, setUpdateLoading] = useState(false)


    useEffect(() => {
      getNecessaryData()
    }, [])

    const getNecessaryData = async () => {
      const GetAllProject = await GetAllProjects()
      const GetAllUsersS = await GetAllUsers()
      const GetAllPriortiesD = await GetAllPriorties()
      setAllCompany(GetAllProject?.data)
      setAllUsers(GetAllUsersS?.data)
      setAllPriorties(GetAllPriortiesD?.data)
    }

    const DeletedTask = async (taskId) => {
      const Attachment = await DeleteTask(taskId)
      if (Attachment?.success == 'success') {
        GetAllTasks()
        handleCancel()
      }
    }
    const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false);
    const showModalUpdate = () => {
      handleCancel()
      setIsModalOpenUpdate(true);
    };
    const handleCancelUpdate = () => {
      setIsModalOpenUpdate(false);
    };

    const submitForm = async (data) => {
      try {
        setUpdateLoading(true)
        const isValid = await createTaskSchema.validate(data);
        if (isValid) {
          const response = await UpdateTask({ ...data, id: taskId })
          if (response?.success == "success") {
            message.success('Successfully Updated')
            handleCancelUpdate()
            GetAllTasks()
            setUpdateLoading(false)
          }
          else if (response?.success !== "success") {
            message.error('Something went wrong')
            handleCancelUpdate()
            GetAllTasks()
            setUpdateLoading(false)
          }
        }
      } catch (error) {
        console.error(error);
        setUpdateLoading(false)
      }
    };


    const {
      control,
      formState: { errors },
      handleSubmit,
      reset
    } = useForm({
      defaultValues: {
        title: taskDetail[0]?.title,
        summary: taskDetail[0]?.summary,
        assignee: taskDetail[0]?.assignee_id,
        estimated_time: taskDetail[0]?.estimated_time?.split(' ')[0],
        project_id: taskDetail[0]?.project_id,
        priority: Number(taskDetail[0]?.priority_id),
      },
      mode: "onChange",
      resolver: yupResolver(createTaskSchema),
    });

    return (
      <>
        <Modal footer={''} open={isModalOpenUpdate} onOk={submitForm} onCancel={handleCancelUpdate}>
          <form onSubmit={handleSubmit(submitForm)}>
            <div>
              <FormInput
                label={'Title'}
                placeholder={'Title'}
                id="title"
                name="title"
                type="text"
                showLabel={true}
                errors={errors}
                control={control}
              />
              <TextArea
                label={'Summary'}
                id="summary"
                name="summary"
                showLabel={true}
                errors={errors}
                control={control}
              />
              <FormSelect
                options={allCompany}
                label={'Project'}
                id="project_id"
                name="project_id"
                showLabel={true}
                errors={errors}
                control={control}
              />
              <FormSelect
                options={allUsers}
                label={'Users'}
                id="assignee"
                name="assignee"
                showLabel={true}
                errors={errors}
                control={control}
              />
              <FormSelect
                options={allPriorties}
                label={'priority'}
                id="priority"
                name="priority"
                showLabel={true}
                errors={errors}
                control={control}
              />
              <FormInput
                label={'Estimated Time'}
                placeholder={''}
                id="estimated_time"
                name="estimated_time"
                type="date"
                showLabel={true}
                errors={errors}
                control={control}
              />
              <div className='d-flex align-items-center justify-content-end px-2'>
                <button disabled={updateLoading} className={Styled.TaskUpdateBtn} type='submit'>{updateLoading ? <Spin /> : "Update"}</button>
              </div>
            </div>
          </form>
        </Modal>
        <div className='pt-3'>
          <Button disabled={updateLoading} onClick={() => showModalUpdate()}>{updateLoading ? <Spin /> : "Edit"} {updateLoading ? "" : <i class="fa fa-pencil-square-o ml-2" aria-hidden="true"></i>}</Button>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => DeletedTask(taskId)}
          >
            <Button className='ml-2'>Delete <i class="fa fa-trash-o ml-2" aria-hidden="true"></i></Button>
          </Popconfirm>
        </div>
      </>
    )
  }


  const TimeAction = () => {
    const [allCompany, setAllCompany] = useState([])
    useEffect(() => {
      getNecessaryData()
    }, [])

    const getNecessaryData = async () => {
      const GetAllProject = await GetTimeByID(taskId)
      setAllCompany(GetAllProject?.data)
    }
    return (
      <>
        <div className={Styled.CommentSection}>
          {allCompany !== undefined && allCompany !== null && allCompany !== "" && allCompany.length > 0 ? allCompany?.map(dat =>
            <div className={Styled?.ListComponentHistory}>
              <div className={Styled?.RoundBallHistory}></div>
              <p>Moved from <span>{dat?.From_destination}</span> to <span>{dat?.To_destination}</span> by <span>{dat?.name}</span> on <span>{DateTime.fromSQL(dat?.time_start).toHTTP("DD")}</span></p>
            </div>
          ) : <Empty description={
            <span>
              No History
            </span>
          } />}
        </div>


      </>
    )
  }

  const itemsUser = [
    {
      key: '1',
      label: 'Comment',
      children: <Comment />,
    },
    {
      key: '2',
      label: 'Description',
      children: <Description />,
    },
    {
      key: '3',
      label: 'Attachment',
      children: <Attachments />,
    },
    {
      key: '4',
      label: 'Action',
      children: <UserAction />,
    },
    {
      key: '5',
      label: 'History',
      children: <TimeAction />,
    },
  ];

  const items = [
    {
      key: '1',
      label: 'Comment',
      children: <Comment />,
    },
    {
      key: '2',
      label: 'Description',
      children: <Description />,
    },
    {
      key: '3',
      label: 'Attachment',
      children: <Attachments />,
    },
    {
      key: '4',
      label: 'History',
      children: <TimeAction />,
    },
  ];

  return (
    <>
      <Modal width={650} footer={''} open={isModalOpen} onCancel={handleCancel}>
        {taskDetailLoading ?
          <>
            <Skeleton className='mt-3' />
            <Skeleton className='mt-3' />
            <Skeleton className='mt-3' />
          </> :
          <div className={Styled.DetailModalBody}>
            {taskDetail?.map(data => (
              <>
                <h3>{data?.title}</h3>
                <div className='d-flex align-items-center'>
                  <div className='pt-4'>
                    <div className={Styled.AfterTitle}>
                      <p>Status:</p>
                    </div>
                    <div className={Styled.AfterTitle}>
                      <p>Assigned to:</p>
                    </div>
                    <div className={Styled.AfterTitle}>
                      <p>Created at:</p>
                    </div>
                    <div className={Styled.AfterTitle}>
                      <p>Project:</p>
                    </div>
                    {/* <div className={Styled.AfterTitle}>
                      <p>Total time:</p>
                    </div> */}
                  </div>
                  <div className='pt-4 pl-3'>
                    <div className={Styled.AfterTitleTextStatus}>
                      <p>{data?.status_name}</p>
                    </div>
                    <div className={Styled.AfterTitleText}>
                      <p>{data?.Assignee}</p>
                    </div>
                    <div className={Styled.AfterTitleText}>
                      <p>{DateTime.fromSQL(data?.created_at).toFormat("DD")}</p>
                    </div>
                    <div className={Styled.AfterTitleText}>
                      <p>{data?.name}</p>
                    </div>
                    {/* <div className={Styled.AfterTitleText}>
                      <p>{data?.name}</p>
                    </div> */}
                  </div>
                </div >
                <div className='pt-3'>
                  <Tabs defaultActiveKey="1" items={taskUserId == userId ? itemsUser : items} />
                </div>
              </>
            ))}
          </div>
        }
      </Modal>


      <Modal width={650} footer={''} open={isAttachment} onCancel={isAttachmentModalCancel}>
        <div className='d-flex flex-column align-items-center justify-content-center pt-5'>
          <Upload fileList={[]} {...propsDR}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
            <p className={Styled.FileNameModal}>{taskFile?.name}</p>
          </Upload>
          {attachmentValidation ?
            <p style={{ color: 'red' }} className='m-0'>Please Select a file</p> : null
          }
        </div>
        <div className='d-flex align-items-center justify-content-end'>
          <Button disabled={attachmentLoader} onClick={SaveFile}>{attachmentLoader ? <Spin /> : 'Save'}</Button>
        </div>
      </Modal>


      <Container
        isDragging={isDragging}
        isGroupedOver={isGroupedOver}
        isClone={isClone}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getStyle(provided, style)}
        data-is-dragging={isDragging}
        data-testid={quote.id}
        data-index={index}
      >
        <div className='w-100'>
          <div style={{ display: 'flex', alignItems: 'center' }} className={Styled.middleBody}>
            <div className=''>
              <h6 className={quote.Priority == "High" ? Styled.PriorityHigh :
                quote.Priority == "Medium" ? Styled.PriorityMedium :
                  quote.Priority == "Low" ? Styled.PriorityLow : ''}
              >{quote.Priority}</h6>
            </div>
            {localStorage.getItem("user_role") == 1 ?
              <div className='pl-2'>
                <h6 className={Styled.Projectname}>{quote?.Assignee_Name}</h6>
              </div>
              :
              <div className='pl-2'>
                <h6 className={Styled.Projectname}>{quote.Project_Title}</h6>
              </div>
            }
          </div>


          <div onClick={() => { showModal(quote?.id, quote?.assignee) }} className={Styled.middleBody}>
            <h6>{quote.Task_Title}</h6>
          </div>
          <div className={Styled.middleBody}>
            <ReadMoreReact text={quote.Task_Summary}
              min={80}
              ideal={90}
              max={100}
              readMoreText="Read more" />
          </div>
          <hr></hr>
          <div className={Styled.UpperCard}>
            <div onClick={() => { isAttachmentModal(quote?.id) }} >
              <h6 className={Styled.FileName}><i class="fa fa-paperclip" aria-hidden="true"></i> {quote?.Task_Count} Attachment</h6>
            </div>
            <h6 className={Styled?.CreatedDate} >
              {DateTime.fromSQL(quote?.created_at).toFormat("DD")}
            </h6>
          </div>
        </div>
      </Container>
    </>
  );
}

function mapStateToProps({ TaskReducer }) {
  return { TaskReducer };
}
export default connect(mapStateToProps, AllTask_Action)(QuoteItem);