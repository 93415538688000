import React from "react";
import style from './input.module.css'
import { Controller } from 'react-hook-form';
import { Select, Switch } from 'antd';
import PhoneInput from 'react-phone-number-input'


const FormInput = ({
    control,
    name,
    label,
    errors,
    showLabel = true,
    type,
    maxLenght,
    className,
    ...rest
}) => {
    return (
        <div className={className} style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
            {showLabel && (
                <label className={style.Label} style={{ fontWeight: '600' }} htmlFor={name}>
                    {label}
                </label>
            )}
            <Controller
                control={control}
                name={name}
                render={({ field }) => {
                    return (
                        <input
                            maxLength={maxLenght}
                            type={type}
                            className={style.Input}
                            {...field}
                            {...rest}
                        />
                    )
                }}
            />
            {errors[name] && (
                <p
                    style={{
                        margin: "5px 0px",
                        fontSize: "12px",
                        color: 'red'
                    }}
                >
                    {errors[name]?.message}
                </p>
            )}
        </div>
    );
};

const FormNumberInput = ({
    control,
    name,
    label,
    errors,
    showLabel = true,
    type,
    maxLenght,
    className,
    placeholder,
    ...rest
}) => {
    return (
        <div className={className} style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
            {showLabel && (
                <label className={style.Label} style={{ fontWeight: '600' }} htmlFor={name}>
                    {label}
                </label>
            )}
            <Controller
                control={control}
                name={name}
                render={({ field }) => {
                    return (
                        <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="PK"
                            placeholder={placeholder}
                            {...field}
                            {...rest}
                        />
                    )
                }}
            />
            {errors[name] && (
                <p
                    style={{
                        margin: "5px 0px",
                        fontSize: "12px",
                        color: 'red'
                    }}
                >
                    {errors[name]?.message}
                </p>
            )}
        </div>
    );
};

const TextArea = ({
    control,
    name,
    label,
    errors,
    showLabel = true,
    type,
    maxLenght,
    className,
    ...rest
}) => {
    return (
        <div className={className} style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
            {showLabel && (
                <label className={style.Label} style={{ fontWeight: '600' }} htmlFor={name}>
                    {label}
                </label>
            )}
            <Controller
                control={control}
                name={name}
                render={({ field }) => {
                    return (
                        <textarea
                            rows={6}
                            className={style.Input}
                            {...field}
                            {...rest}
                        ></textarea>
                        // <input
                        // maxLength={maxLenght}
                        //     type={type}
                        //     className={style.Input}
                        //     {...field}
                        //     {...rest}
                        // />
                    )
                }}
            />
            {errors[name] && (
                <p
                    style={{
                        margin: "5px 0px",
                        fontSize: "12px",
                        color: 'red'
                    }}
                >
                    {errors[name]?.message}
                </p>
            )}
        </div>
    );
};

const FormCheckBox = ({
    control,
    name,
    label,
    errors,
    IsPassword,
    type,
    options,
    isShowError,
    showLabel = true,
    labelText,
    ...rest
}) => {
    return (
        <div>
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <>
                        {showLabel && (
                            <label htmlFor="continue-no" className="">{labelText}</label>
                        )}
                        <div className="">
                            <input
                                {...field}
                                {...rest}
                                id={name} type={type} name={name} className="" />
                            {showLabel && (
                                <label htmlFor="continue-no" className="">{label}</label>
                            )}
                        </div>
                    </>
                )}
            />
            {errors[name] && (
                isShowError == true ?
                    <p
                        style={{
                            margin: "5px 0px",
                            fontSize: "12px",
                            color: 'red'
                        }}
                    >
                        {errors[name]?.message}
                    </p>
                    : null
            )}
        </div>
    );
};

const FormSelect = ({
    control,
    name,
    label,
    errors,
    options,
    isShowError,
    placeholder,
    deduction,
    showLabel = true,
    defaultValue,
    ...rest
}) => {
    const options2 = [];
    if (options) {
        options?.map((t) =>
        options2.push({
            value: t?.id,
            label: t?.name,
        })
        )
    }
    console.log(options,options2,'options')
    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', }}>
            {showLabel && (
                <label className={style.Label} style={{ fontWeight: '600' }}>
                    {label}
                </label>
            )}
            <Controller
                control={control}
                name={name}
                render={({ field }) => {
                    return (
                        <Select
                            style={{ width: "100% !important" }}
                            {...field}
                            {...rest}
                            showSearch={false}
                            placeholder={placeholder}
                            name={name} id={name}
                            options={options2}
                        />
                    )
                }}
            />
            {errors[name] && (
                <p
                    style={{
                        margin: "5px 0px",
                        fontSize: "12px",
                        color: 'red'
                    }}
                >
                    {errors[name]?.message}
                </p>
            )}
        </div>
    );
};



const FormSwitch = ({
    control,
    name,
    label,
    errors,
    options,
    isShowError,
    placeholder,
    deduction,
    showLabel = true,
    defaultValue,
    ...rest
}) => {
    const options2 = [];
    if (options) {
        options?.map((t) =>
            options2.push({
                value: t?.id,
                label: t?.name,
            })
        )
    }
    return (
        <div style={{ display: 'flex', alignItems: "center", padding: '10px', }}>
            {showLabel && (
                <label className={style.Label} style={{ fontWeight: '600' }}>
                    {label}
                </label>
            )}
            <Controller
                control={control}
                name={name}
                render={({ field }) => {
                    return (
                        <Switch style={{ width: '10px', marginLeft: '10px' }}  {...field} {...rest} />
                    )
                }}
            />
            {errors[name] && (
                <p
                    style={{
                        margin: "5px 0px",
                        fontSize: "12px",
                        color: 'red'
                    }}
                >
                    {errors[name]?.message}
                </p>
            )}
        </div>
    );
};


const Input = ({ type, placeholder, label, readonly, value, onChange, max, onEnterPress, name, error, refe }) => {
    return (
        <>
            <div className={`${style.Label} px-2`} id="inputBox">
                <label className="m-0 p-0">{label}</label>
                <input ref={refe} onKeyDown={onEnterPress} name={name} maxLength={max} onChange={(e) => onChange(e)} readOnly={readonly} className={style.Input} type={type} placeholder={placeholder} />
                {error ? <p>File is required</p> : null}
            </div>
        </>
    )
}



export { FormInput, FormCheckBox, FormSelect, Input, TextArea, FormNumberInput, FormSwitch };